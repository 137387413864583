// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  width: 550px;
  background-color: #000;
  height: 700px;
  border-radius: 1rem;
  padding: 2rem;
  margin: 1rem;
}

@media only screen and (max-width: 620px) {
  .card {
    width: 90vw;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media only screen and (max-height: 670px) {
  .card {
    height: 650px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd;;AAEA;EACE;IACE,WAAW;IACX,oBAAoB;IACpB,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".card {\n  width: 550px;\n  background-color: #000;\n  height: 700px;\n  border-radius: 1rem;\n  padding: 2rem;\n  margin: 1rem;\n}\n\n@media only screen and (max-width: 620px) {\n  .card {\n    width: 90vw;\n    padding-left: 1.5rem;\n    padding-right: 1.5rem;\n  }\n}\n\n@media only screen and (max-height: 670px) {\n  .card {\n    height: 650px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
