import React, { useEffect, useState } from 'react';
import { Button, Layout, theme, Tooltip } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, InfoCircleOutlined } from '@ant-design/icons'
import Logo from './Hometag/Logo';
import MenuList from './Hometag/MenuList';
import "./Home.css";
import ToggleThemeButton from './Hometag/ToggleThemeButton';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom"

import ChatGPT from './Hometag/ChatGPT';
import Document from './Hometag/Document';
import StructureFile from './Hometag/StructureFile';
import Webpage from './Hometag/Webpage';
import ImageGen from './Hometag/ImageGen';
import WineCom from './Hometag/WineCom';
import HomeContent from './Hometag/HomeContent'
import DemoVideo from './Hometag/demoVideo';

const { Header, Sider} =  Layout;

const Home: React.FC = () => {
    const [darkTheme, setdarkTheme] = useState(false)
    const [collapsed, setCollapsed] = useState(false)
    const [noted, setNoted] = useState(true)
    const [oldPath, setOldPath] = useState("/")

    console.log("Home")
    
    const location = useLocation();
    const currentRoute = location.pathname;

    if (oldPath != currentRoute){
      setNoted(true)
      setOldPath(currentRoute)
    }
    
    const [currentContent, setCurrentContent] = useState(currentRoute === '/' ? '/homeContent' : currentRoute);

    const ToggleTheme = () => {
        setdarkTheme(!darkTheme)
    };
    

    const {
        token: {colorBgContainer},
    } =  theme.useToken()

    const navigate = useNavigate();
    
    const handleMenuClick = (key: string) => {
        setCurrentContent(key);
        navigate(key);
      };

    const themeClassName = darkTheme ? 'dark-theme' : 'light-theme';

    let title = '';

    if ((currentContent === '/homeContent') || (currentContent === '/homeContent')) {
      title = 'This is a product of New Ocean Information System. The product provides various chatbots that offer information about NOIS, chatbots are capable for structured or unstructured documents, webpages, and more. Most of these chatbots are powered by Azure OpenAI.';
    } else if (currentContent === '/chatGPT') {
      title = 'This is NOIS GPT. In addition to the general information that a GPT can provide, NOIS GPT is capable of answering questions about New Ocean Information System company as well.';
    } else if (currentContent === '/document') {
      title = 'This is Document Chatbot. The Document Chatbot is capable of answering any question related to the contents of your uploaded file. These files can be in PDF, DOC, PPTX, or TXT format, and you have the ability to upload them yourself.';
    } else if (currentContent === '/structureFile') {
      title = 'This is Structure File Chatbot. The Structured Chatbot is capable of providing answers to inquiries pertaining to a public market database. It can address various aspects such as ID, item code, item name, date, amount, price, and more. For specific details, you can refer to the file through the following link: https://drive.google.com/file/d/1WH-t90QrGhyJ6Uh1lPTg8kWGUYLbLnzf/view?usp=sharing';
    } else if (currentContent === '/webpage') {
      title = 'This is the Webpage Chatbot. The Webpage Chatbot is designed to respond to queries related to the website provided in the link you provided.';
    } else if (currentContent === '/wineInfor') {
      title = 'This is the Wine Information Chatbot. The Wine Chatbot is capable of providing answers to inquiries regarding wine information based on a public dataset containing 200,000 different types of wines. You can ask about various aspects include name, type, grapes, wine aging location, aging year, alcohol degree, level of acidity, as well as suitable food pairings. You can refer to the file through the following link:  https://drive.google.com/file/d/1hrfpAsWcQKbkxyq4ciWzyJHoWhXvg6V2/view?usp=sharing';
    } else if (currentContent === '/image') {
      title = 'This is Image Generator. We are deveploping so this functionality is temporarily suspended.';
    } else if (currentContent === '/demoVideo') {
      title = 'This is demo Video to instruct how to use NOIS GPT.';
    }
  
    useEffect(() => {
        function handleResize() {    
          if (window.innerWidth < 600) {
            setCollapsed(true);
          }
          else if (window.innerWidth >= 600) {
            setCollapsed(false);
          }
        }
    
        window.addEventListener('resize', handleResize);
    
        handleResize();
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <Layout className={themeClassName} >
            <Sider collapsed={collapsed} 
            collapsible
            trigger={null}
            theme={darkTheme ? 'dark' : 'light'} 
            className={`sidebar-${themeClassName}`}>
                <Logo />
                <MenuList darkTheme={darkTheme} onClick={handleMenuClick} collapsed={collapsed} />
                <ToggleThemeButton darkTheme={darkTheme} toggleTheme={ToggleTheme}/>
            </Sider>

            <Layout className={themeClassName}>
                <Header className={`taskbar-${themeClassName} ${themeClassName}`} style={{padding: 20, display: 'flex', justifyContent: 'space-between' }}>
                <Button 
                    type='text' 
                    className='toggle'
                    onClick={() => setCollapsed(!collapsed)}
                    icon={collapsed ? <MenuUnfoldOutlined className={themeClassName} /> : <MenuFoldOutlined className={themeClassName} />}

                />

                <Tooltip title={title} trigger="click" className={themeClassName} fresh={true} open={noted} >
                  <Button 
                      // type='text' 
                      // className='toggle'
                      onClick={() => setNoted(!noted)}
                      icon={collapsed ? <InfoCircleOutlined className={themeClassName} /> : <InfoCircleOutlined className={themeClassName} />}
                  />
                </Tooltip>
              
                </Header>

                {currentContent === '/homeContent' && <HomeContent themeClassName={themeClassName}/>}
                {currentContent === '/chatGPT' && <ChatGPT themeClassName={themeClassName}/>}
                {currentContent === '/document' && <Document themeClassName={themeClassName}/>}
                {currentContent === '/structureFile' && <StructureFile themeClassName={themeClassName}/>}
                {currentContent === '/webpage' && <Webpage themeClassName={themeClassName}/>}
                {currentContent === '/wineInfor' && <WineCom themeClassName={themeClassName}/>}
                {currentContent === '/image' && <ImageGen themeClassName={themeClassName}/>} 
                {currentContent === '/demoVideo' && <DemoVideo themeClassName={themeClassName}/>}
                
            </Layout>
        </Layout>

    );
}


export default Home;